<template>
  <div class="user-coupon-detail">
    <div class="list">
      <div class="box">
        <div class="item">
          <div class="head">
            <div class="city">{{ details.cityName }}</div>
            <template v-if="details.couponsType == 1">
              <div class="num"><span>{{ (details.percentOff / 10).toFixed(1) }}折</span></div>
              <div class="tip"><span>折扣券</span></div>
            </template>
            <template v-else>
              <div class="num">￥<span>{{ details.priceOff * 1 / 100 }}</span></div>
              <div class="tip"><span>抵扣券</span></div>
            </template>
          </div>
          <div class="cont">
            <div class="title">{{ details.couponsTitle }}{{ details.couponsType == 1 ? '折扣券' : '抵扣券' }}</div>
            <div class="bottom">
              <div class="date">有效期至 : {{ details.validEndTime | parseTime }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="head">使用须知</div>
      <div v-if="details.remark" class="cont">
        <p v-for="item in details.remark.split('；')" :key="item">{{ item }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import { parseTime } from '@/utils/index'
export default {
  filters: {
    parseTime(val) {
      return parseTime(val, '{y}-{m}-{d}')
    }
  },
  data() {
    return {
      details: {}
    }
  },
  mounted() {
    const details = this.$route.params.details
    if (details) {
      this.details = details
    } else {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang="scss" scoped>
.user-coupon-detail {
  .list {
    padding-top: 10px;
    .box {
      padding: 0 10px 10px 10px;
    }
    .item {
      display: flex;
      width: 361px;
      height: 122px;
      background-image:url(~@/assets/user/coupon@2x.png);
      background-size: 100%;
      .head {
        width: 106px;
        padding: 16px 0 0 9px;
        color: #fff;
        .city {
          padding-left: 10px;
        }
        .num {
          padding-top: 10px;
          text-align: center;
          span {
            font-size: 24px;
          }
        }
        .tip {
          display: flex;
          justify-content: center;
          span {
            margin-top: 10px;
            border: 1px solid #fff;
            font-size: 10px;
            padding: 4px 8px;
            border-radius: 16px;
          }
        }
      }
      .cont {
        display: flex;
        flex-flow: column;
        justify-content: center;
        width: 200px;
        padding: 0 0 0 16px;
        .title {
          font-size: 16px;
        }
        .bottom {
          display: flex;
          padding-top: 10px;
          font-size: 12px;
          .date {
            flex: 1;
            color: #999999;
          }
        }
      }
    }
  }
  .content {
    padding: 16px;
    .head {
      font-size: 14px;
      font-weight: bold;
    }
    .cont {
      padding-top: 16px;
      color: #666666;
      line-height: 20px;
    }
  }
}
</style>
